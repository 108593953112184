body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }
  
  .carousel {
    position: relative;
    width: 100%;
    height: 88vh; /* Adjust the height as needed */
    overflow: hidden;
    background-color: #fff; /* Add your background image */
    background-size: cover; /* Ensure the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    padding-top: 50px; /* Add padding to account for top margin */
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 30px; /* Allow height to adjust based on content */
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    min-width: 100%;
    height: 100%; /* Adjust height automatically based on video */
    position: relative;
    display: none;
    box-sizing: border-box; /* Ensures border is included in width/height */
  }
  
  .carousel-item.active {
    display: block;
  }
  
  .carousel-item video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure video covers the container */
    display: block; /* Prevents video from overflowing */
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%; /* Circular shape */
    color: #fff;
    padding: 10px;
    font-size: 2em;
    cursor: pointer;
    z-index: 100;
  }
  
  .prev {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  
  .prev:hover,
  .next:hover {
    background-color: red;
  }
  
  .carousel-thumbnails {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: none; /* Hide thumbnails on all screens */
  }
  
  .thumbnail-item {
    width: 100px;
    height: 70px;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease;
  }
  
  .thumbnail-item.active {
    opacity: 1;
  }
  
  .thumbnail-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .thumbnail-item:hover {
    opacity: 1;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .carousel {
      height: auto; /* Allow height to adjust based on video */
    }
  
    .carousel-item {
      padding-top: 20px; /* Increased top margin to avoid navbar overlap */
    }
  
    .prev,
    .next {
      padding: 8px;
      font-size: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .carousel {
      height: auto; /* Allow height to adjust based on video */
    }
  
    .carousel-item {
      padding-top: 30px; /* Increased top margin to avoid navbar overlap */
    }
  
    .prev,
    .next {
      padding: 6px;
      font-size: 1.2em;
    }
  }
  
  /* Animation for entering/exiting text */
  @keyframes fadeInText {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  